// Interface para as opções de field_type
export enum KitDisplayModeOptions {
  DEFAULT = 'default',
}

export enum BuyTogetherDisplayModeOptions {
  GRID = 'grid',
  LIST = 'list',
  MINIMAL = 'minimal',
}

export enum VariationsModeOptions {
  SELECT = 'select',
  BUTTONS = 'buttons',
}

export enum MethodOptions {
  APPEND = 'append',
  PREPEND = 'prepend',
  IN = 'in',
  AFTER = 'after',
  BEFORE = 'before',
}

export interface IStoreConfig {
  _id?: string;
  store_id?: number;
  buy_together_display_mode: BuyTogetherDisplayModeOptions;
  variations_mode: VariationsModeOptions;
  buy_together_desktop_position: string;
  buy_together_desktop_position_method: MethodOptions;
  buy_together_mobile_position: string;
  buy_together_mobile_position_method: MethodOptions;
  buy_together_title: string;
  buy_together_message_discount: string;
  buy_together_cta_text: string;
  buy_together_disabled_option_text: string;
  buy_together_enabled_option_text: string;
  success_color: string;
  primary_color: string;
  secondary_color: string;
  title_color: string;
  text_color: string;
  border_color: string;
  custom_colors: boolean;
  kit_display_mode: KitDisplayModeOptions;
  is_active: boolean;
  createdAt?: string;
  updatedAt?: string;
}

// Valor padrão
export const defaultStoreConfig: IStoreConfig = {
  buy_together_display_mode: BuyTogetherDisplayModeOptions.GRID,
  variations_mode: VariationsModeOptions.BUTTONS,
  buy_together_desktop_position: '[data-store*="product-info-"]',
  buy_together_desktop_position_method: MethodOptions.AFTER,
  buy_together_mobile_position: '[data-store*="product-info-"]',
  buy_together_mobile_position_method: MethodOptions.AFTER,
  buy_together_title: 'Compre Junto',
  buy_together_message_discount: 'Leve {qtd} itens e economize {valor}',
  buy_together_cta_text: 'Adicionar tudo',
  buy_together_disabled_option_text: 'Não quero',
  buy_together_enabled_option_text: 'Quero',
  success_color: '#25d058',
  primary_color: '#358ebb',
  secondary_color: '#363636',
  title_color: '#383838',
  text_color: '#404040',
  border_color: '#f5f5f5',
  custom_colors: true,
  kit_display_mode: KitDisplayModeOptions.DEFAULT,
  is_active: true,
};
