import { ChangeEvent, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Card,
  Icon,
  IconButton,
  Input,
  Label,
  Pagination,
  Sidebar,
  Spinner,
  Text,
  Thumbnail,
  Title,
  Toggle,
  useToast,
} from '@nimbus-ds/components';
import {
  DataList,
  EmptyMessage,
  InteractiveList,
  Layout,
  Page,
} from '@nimbus-ds/patterns';
import { navigateHeader } from '@tiendanube/nexo/helpers';

import nexo from '../../nexoClient';
import { useHistory, useParams } from 'react-router-dom';
import apiInstance from '../../utils/apiUtils';
import { IBrands, EmptyBrand } from '../../lib/interfaces/brands.interfaces';
import { IPaginateMeta } from '../../lib/interfaces/paginate.interfaces copy';
import {
  PlusCircleIcon,
  SearchIcon,
  TrashIcon,
  UndoIcon,
} from '@nimbus-ds/icons';
import { InputColor } from '../../components';

function BrandPage() {
  const { id } = useParams<{ id: string }>();
  const { addToast } = useToast();
  const { push } = useHistory();

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingGet, setLoadingGet] = useState<boolean>(false);
  const [lang, setLang] = useState<string>('pt');
  const [typeSearchProduct, setTypeSearchProduct] = useState<
    'product' | 'rule'
  >('product');
  const [brand, setBrand] = useState<IBrands>({ ...EmptyBrand });
  const [loadingProducts, setLoadingProducts] = useState<boolean>(true);
  const [loadingCustomers, setLoadingCustomers] = useState<boolean>(true);
  const [openSearchProdut, setOpenSearchProdut] = useState<boolean>(false);
  const [openSearchCustomer, setOpenSearchCustomer] = useState<boolean>(false);
  const [products, setProducts] = useState<any[]>([]);
  const [customers, setCustomers] = useState<any[]>([]);
  const [productsMeta, setProductsMeta] = useState<IPaginateMeta>();
  const [customersMeta, setCustomersMeta] = useState<IPaginateMeta>();
  const [searchProductQuery, setSearchProductQuery] = useState<string>('');
  const [searchCustomerQuery, setSearchCustomerQuery] = useState<string>('');

  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(
    null,
  );

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: `Voltar` });

    if (id && id.length) {
      getBrand();
    }
    setLoading(false);
  }, []);

  const handleChangeBrand = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value, type } = event.target;
    const updatedData: any = { ...brand };
    let currentField = updatedData;
    const fieldPath = name.split('.');

    fieldPath.forEach((field, index) => {
      if (index === fieldPath.length - 1) {
        // if (field in currentField) {
        if (type === 'checkbox' && event.target instanceof HTMLInputElement) {
          currentField[field] = event.target.checked;
        } else if (type === 'number') {
          const isFloat = value.includes('.');

          // Converte o valor para inteiro ou float, dependendo do resultado
          currentField[field] = isFloat
            ? parseFloat(value)
            : parseInt(value, 10);
        } else {
          currentField[field] = value;
        }
        // }
      } else {
        currentField = currentField[field];
      }
    });
    console.log(updatedData);

    setBrand(updatedData);
  };

  const getBrand = async () => {
    setLoadingGet(true);
    try {
      const result = await apiInstance.get(`/brand/${id}`);
      if (result.data !== null) {
        setBrand(result.data as IBrands);
      }
    } catch (error) {
      addToast({
        id: 'error_get' + new Date().toISOString(),
        type: 'danger',
        text: `Não conseguimos recuperar as informações da marca`,
      });
    }
    setLoadingGet(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const payload: { [key: string]: any } = { ...brand };
      let result;
      if (id) {
        delete payload._id;
        delete payload.createdAt;
        delete payload.updatedAt;
        result = await apiInstance.put(`/brand/${id}`, payload);
      } else {
        result = await apiInstance.post(`/brand`, payload);
      }
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'success',
        text: `Marca salva`,
      });
      setLoading(false);
      if (id === undefined && result.data) {
        push(`/`);
      }
    } catch (error) {
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'danger',
        text: `Não foi possivel salvar a marca`,
      });
      setLoading(false);
    }
  };

  const searchProducts = async (page = 1, reset = false) => {
    setLoadingProducts(true);
    try {
      const result = await apiInstance.get(
        `/store/products?q=${reset ? '' : searchProductQuery}&page=${page}`,
      );
      if (result.data !== null) {
        setProducts(result.data.result);
        setProductsMeta(result.data.meta as IPaginateMeta);
        setLang(result.data.meta!.lang || 'pt');
      }
    } catch (error) {
      setProducts([]);
    }
    setLoadingProducts(false);
  };
  const handleAddProductToBrand = async (
    event: ChangeEvent<HTMLInputElement>,
    product: any,
  ) => {
    const { checked } = event.target;
    if (checked) {
      const lang = product.default_lang || 'pt';
      if (typeSearchProduct === 'product') {
        brand.products.push({
          name: product.name[lang],
          id: product.id,
        });
      } else {
        brand.cart_rules.push({
          name: product.name[lang],
          id: product.id,
          min_first_order: 0,
          min: 0,
        });
      }
    } else {
      if (typeSearchProduct === 'product') {
        for (let x = 0; x < brand.products.length; x++) {
          if (brand.products[x].id === product.id) {
            brand.products.splice(x, 1);
          }
        }
      } else {
        for (let x = 0; x < brand.cart_rules.length; x++) {
          if (brand.cart_rules[x].id === product.id) {
            brand.cart_rules.splice(x, 1);
          }
        }
      }
    }
    setBrand({ ...brand });
  };
  const handleChangeProductQuerySearch = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    setSearchProductQuery(value);

    // Limpa o timeout anterior, se existir
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Define um novo timeout de 500 milissegundos (ou o valor desejado)
    const newTimeout = setTimeout(() => {
      searchProducts(productsMeta?.page || 1);
    }, 500);

    setSearchTimeout(newTimeout);

    return false;
  };
  const handleRemoveProductForBrand = (
    type: 'product' | 'rule',
    index: number,
  ) => {
    if (type === 'product') {
      if (brand.products[index]) {
        brand.products.splice(index, 1);
        setBrand({ ...brand });
        addToast({
          type: 'success',
          text: 'Produto desvinculado',
          id: 'handleRemoveProductForBrand' + new Date().toISOString(),
        });
        return false;
      }
      addToast({
        type: 'danger',
        text: 'Erro ao desvincular produto',
        id: 'handleRemoveProductForBrand' + new Date().toISOString(),
      });
    } else {
      if (brand.cart_rules[index]) {
        brand.cart_rules.splice(index, 1);
        setBrand({ ...brand });
        addToast({
          type: 'success',
          text: 'Produto desvinculado',
          id: 'handleRemoveProductForBrand' + new Date().toISOString(),
        });
        return false;
      }
      addToast({
        type: 'danger',
        text: 'Erro ao desvincular produto',
        id: 'handleRemoveProductForBrand' + new Date().toISOString(),
      });
    }
    return false;
  };
  const handleOpenSidebarProduct = (type: 'product' | 'rule') => {
    setTypeSearchProduct(type);
    searchProducts();
    setOpenSearchProdut(true);
  };

  const searchCustomers = async (page = 1, reset = false) => {
    setLoadingCustomers(true);
    try {
      const result = await apiInstance.get(
        `/store/customers?q=${
          reset ? '' : encodeURIComponent(searchCustomerQuery)
        }&page=${page}`,
      );
      if (result.data !== null) {
        setCustomers(result.data.result);
        setCustomersMeta(result.data.meta as IPaginateMeta);
      }
    } catch (error) {
      setCustomers([]);
    }
    setLoadingCustomers(false);
  };
  const handleAddCustomersToBrand = async (
    event: ChangeEvent<HTMLInputElement>,
    customer: any,
  ) => {
    const { checked } = event.target;
    if (checked) {
      brand.customers.push({
        name: customer.name,
        id: customer.id,
        email: customer.email,
        phone: customer.phone,
        identification: customer.identification || '',
      });

      try {
        const result = await apiInstance.get(`/brand/customer/${customer.id}`);
        if (result.data) {
          for (let x = 0; x < result.data.length; x++) {
            const item = result.data[x];
            addToast({
              type: 'danger',
              text: `Cliente já cadastrado na marca ${item.name}`,
              id: 'erro_customer_other_brand' + new Date().toISOString(),
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      for (let x = 0; x < brand.customers.length; x++) {
        if (brand.customers[x].id === customer.id) {
          brand.customers.splice(x, 1);
        }
      }
    }
    setBrand({ ...brand });
  };
  const handleChangeCustomerQuerySearch = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;

    setSearchCustomerQuery(value);

    // Limpa o timeout anterior, se existir
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Define um novo timeout de 500 milissegundos (ou o valor desejado)
    const newTimeout = setTimeout(() => {
      searchCustomers(productsMeta?.page || 1);
    }, 500);

    setSearchTimeout(newTimeout);

    return false;
  };
  const handleRemoveCustomerForBrand = (index: number) => {
    if (brand.customers[index]) {
      brand.customers.splice(index, 1);
      setBrand({ ...brand });
      addToast({
        type: 'success',
        text: 'Cliente desvinculado',
        id: 'handleRemoveProductForBrand' + new Date().toISOString(),
      });
      return false;
    }
    addToast({
      type: 'danger',
      text: 'Erro ao desvincular cliente',
      id: 'handleRemoveProductForBrand' + new Date().toISOString(),
    });
    return false;
  };
  const handleOpenSidebarCustomer = () => {
    searchCustomers();
    setOpenSearchCustomer(true);
  };

  return (
    <>
      <Page maxWidth="100%">
        <Page.Header
          title={id ? `Alterar marca ${brand.name}` : 'Cadastrar nova marca'}
          buttonStack={<></>}
        />
        <Page.Body>
          <Box display="grid" gap="6">
            <Card>
              <Card.Body>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  gap="4"
                >
                  {loadingGet ? (
                    <>
                      <Toggle.Skeleton />
                      <Input.Skeleton />
                    </>
                  ) : (
                    <>
                      <Toggle
                        name="active"
                        label="Ativo"
                        disabled={loading || brand === null}
                        active={brand.active}
                        onChange={handleChangeBrand}
                      />
                      <Input
                        disabled={loading}
                        id={`name`}
                        placeholder="Nome da marca"
                        name={`name`}
                        required={true}
                        onChange={handleChangeBrand}
                        value={brand.name}
                      />
                    </>
                  )}
                </Box>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header title="Cores" />
              <Card.Body>
                <Box display="grid" gap="10">
                  <Box display="flex" justifyContent="space-between" gap="6">
                    <InputColor
                      label="Cor padrão"
                      name="colors.main_foreground"
                      onChange={handleChangeBrand}
                      required={true}
                      disabled={loading || !brand}
                      value={brand?.colors.main_foreground || '#ffffff'}
                    />
                    <InputColor
                      label="Background padrão"
                      name="colors.main_background"
                      onChange={handleChangeBrand}
                      required={true}
                      disabled={loading || !brand}
                      value={brand?.colors.main_background || '#ffffff'}
                    />
                    <InputColor
                      label="Cor de destaque"
                      name="colors.accent_color"
                      onChange={handleChangeBrand}
                      required={true}
                      disabled={loading || !brand}
                      value={brand?.colors.accent_color || '#ffffff'}
                    />
                    <InputColor
                      label="Cor secundaria"
                      name="colors.secondary_color"
                      onChange={handleChangeBrand}
                      required={true}
                      disabled={loading || !brand}
                      value={brand?.colors.secondary_color || '#ffffff'}
                    />
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexWrap="nowrap"
                    gap="6"
                  >
                    <Box display="grid" gap="6">
                      <Text fontWeight="bold">Botões</Text>
                      <InputColor
                        label="Texto dos botões"
                        name="colors.button_foreground"
                        onChange={handleChangeBrand}
                        required={true}
                        disabled={loading || !brand}
                        value={brand?.colors.button_foreground || '#ffffff'}
                      />
                      <InputColor
                        label="Fundos dos botões"
                        name="colors.button_background"
                        onChange={handleChangeBrand}
                        required={true}
                        disabled={loading || !brand}
                        value={brand?.colors.button_background || '#ffffff'}
                      />
                    </Box>
                    <Box display="grid" gap="6">
                      <Text fontWeight="bold">Labels</Text>
                      <InputColor
                        label="Texto dos Labels"
                        name="colors.label_foreground"
                        onChange={handleChangeBrand}
                        required={true}
                        disabled={loading || !brand}
                        value={brand?.colors.label_foreground || '#ffffff'}
                      />
                      <InputColor
                        label="Fundos dos Labels"
                        name="colors.label_background"
                        onChange={handleChangeBrand}
                        required={true}
                        disabled={loading || !brand}
                        value={brand?.colors.label_background || '#ffffff'}
                      />
                    </Box>
                    <Box display="grid" gap="6">
                      <Text fontWeight="bold">Cabeçalho</Text>
                      <InputColor
                        label="Texto do Cabeçalho"
                        name="colors.header_foreground"
                        onChange={handleChangeBrand}
                        required={true}
                        disabled={loading || !brand}
                        value={brand?.colors.header_foreground || '#ffffff'}
                      />
                      <InputColor
                        label="Fundos do Cabeçalho"
                        name="colors.header_background"
                        onChange={handleChangeBrand}
                        required={true}
                        disabled={loading || !brand}
                        value={brand?.colors.header_background || '#ffffff'}
                      />
                    </Box>
                    <Box display="grid" gap="6">
                      <Text fontWeight="bold">Rodapé</Text>
                      <InputColor
                        label="Texto do Rodapé"
                        name="colors.footer_foreground"
                        onChange={handleChangeBrand}
                        required={true}
                        disabled={loading || !brand}
                        value={brand?.colors.footer_foreground || '#ffffff'}
                      />
                      <InputColor
                        label="Fundos do Rodapé"
                        name="colors.footer_background"
                        onChange={handleChangeBrand}
                        required={true}
                        disabled={loading || !brand}
                        value={brand?.colors.footer_background || '#ffffff'}
                      />
                    </Box>
                  </Box>
                </Box>
              </Card.Body>
            </Card>
            <Layout columns="3">
              {/* CLIENTES */}
              <Layout.Section>
                <Card padding="none">
                  <Card.Header>
                    <Box
                      padding="4"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Title as="h3">Clientes</Title>
                      <Button
                        appearance="neutral"
                        onClick={handleOpenSidebarCustomer}
                      >
                        <Icon
                          source={<PlusCircleIcon />}
                          color="currentColor"
                        />
                        Vincular clientes
                      </Button>
                    </Box>
                  </Card.Header>
                  <Card.Body padding="none">
                    {brand.customers.length ? (
                      <DataList>
                        {brand.customers.map((customer, i) => (
                          <DataList.Row key={customer.id} gap="1">
                            <Box display="flex" justifyContent="space-between">
                              <Box display="grid" gap="1">
                                <Text
                                  fontWeight="medium"
                                  color="primary-interactive"
                                >
                                  #{customer.id} - {customer.name}
                                </Text>
                                {customer.identification ? (
                                  <Text>CNPJ: {customer.identification}</Text>
                                ) : (
                                  <></>
                                )}
                              </Box>
                              <IconButton
                                source={<TrashIcon size="small" />}
                                size="30px"
                                onClick={() => handleRemoveCustomerForBrand(i)}
                              />
                            </Box>
                          </DataList.Row>
                        ))}
                      </DataList>
                    ) : (
                      <EmptyMessage
                        title="Clientes vinculados"
                        text="Nenhum cliente vinculado a esta marca"
                        icon={<SearchIcon size={32} />}
                        actions={
                          <Button
                            appearance="transparent"
                            onClick={handleOpenSidebarCustomer}
                          >
                            <Icon
                              source={<PlusCircleIcon />}
                              color="currentColor"
                            />
                            selecionar clientes
                          </Button>
                        }
                      />
                    )}
                  </Card.Body>
                </Card>
              </Layout.Section>
              {/* PRODUTOS */}
              <Layout.Section>
                <Box display="grid" gap="6">
                  <Card padding="none">
                    <Card.Header>
                      <Box
                        padding="4"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Title as="h3">Produtos</Title>
                        <Button
                          appearance="neutral"
                          onClick={() => handleOpenSidebarProduct('product')}
                        >
                          <Icon
                            source={<PlusCircleIcon />}
                            color="currentColor"
                          />
                          Vincular produtos
                        </Button>
                      </Box>
                    </Card.Header>
                    <Card.Body padding="none">
                      {brand.products.length ? (
                        <DataList>
                          {brand.products.map((product, i) => (
                            <DataList.Row key={product.id} gap="1">
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Text
                                  fontWeight="medium"
                                  color="primary-interactive"
                                >
                                  #{product.id} - {product.name}
                                </Text>
                                <IconButton
                                  source={<TrashIcon size="small" />}
                                  size="30px"
                                  onClick={() =>
                                    handleRemoveProductForBrand('product', i)
                                  }
                                />
                              </Box>
                            </DataList.Row>
                          ))}
                        </DataList>
                      ) : (
                        <EmptyMessage
                          title="Produtos vinculados"
                          text="Nenhum produto vinculado a esta marca"
                          icon={<SearchIcon size={32} />}
                          actions={
                            <Button
                              appearance="transparent"
                              onClick={() =>
                                handleOpenSidebarProduct('product')
                              }
                            >
                              <Icon
                                source={<PlusCircleIcon />}
                                color="currentColor"
                              />
                              selecionar produtos
                            </Button>
                          }
                        />
                      )}
                    </Card.Body>
                  </Card>
                </Box>
              </Layout.Section>
              {/* RULES */}
              <Layout.Section>
                <Box display="grid" gap="6">
                  <Card padding="none">
                    <Card.Header>
                      <Box
                        padding="4"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Title as="h3">Minimo para compra</Title>
                        <Button
                          appearance="neutral"
                          onClick={() => handleOpenSidebarProduct('rule')}
                        >
                          <Icon
                            source={<PlusCircleIcon />}
                            color="currentColor"
                          />
                          produtos
                        </Button>
                      </Box>
                    </Card.Header>
                    <Card.Body padding="none">
                      {brand.cart_rules.length ? (
                        <DataList>
                          {brand.cart_rules.map((product, i) => (
                            <DataList.Row key={product.id} gap="1">
                              <Box display="grid" gap="4">
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                >
                                  <Text
                                    fontWeight="medium"
                                    color="primary-interactive"
                                  >
                                    #{product.id} - {product.name}
                                  </Text>
                                  <IconButton
                                    source={<TrashIcon size="small" />}
                                    size="30px"
                                    onClick={() =>
                                      handleRemoveProductForBrand('rule', i)
                                    }
                                  />
                                </Box>
                                <Label>Qtd. minima</Label>
                                <Box display="flex" gap="4">
                                  <Box display="grid" gap="1">
                                    <Label>Geral</Label>
                                    <Input
                                      disabled={loading}
                                      id={`cart_rules.${i}.min`}
                                      name={`cart_rules.${i}.min`}
                                      type="number"
                                      min={0}
                                      step={1}
                                      onChange={handleChangeBrand}
                                      value={product.min}
                                    />
                                  </Box>
                                  <Box display="grid" gap="1">
                                    <Label>Primeira compra</Label>
                                    <Input
                                      disabled={loading}
                                      id={`cart_rules.${i}.min_first_order`}
                                      name={`cart_rules.${i}.min_first_order`}
                                      type="number"
                                      min={0}
                                      step={1}
                                      onChange={handleChangeBrand}
                                      value={product.min_first_order}
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            </DataList.Row>
                          ))}
                        </DataList>
                      ) : (
                        <EmptyMessage
                          title="Regra de carrinho"
                          text="Nenhuma regra vinculado a esta marca"
                          icon={<SearchIcon size={32} />}
                          actions={
                            <Button
                              appearance="transparent"
                              onClick={() => handleOpenSidebarProduct('rule')}
                            >
                              <Icon
                                source={<PlusCircleIcon />}
                                color="currentColor"
                              />
                              selecionar produtos
                            </Button>
                          }
                        />
                      )}
                    </Card.Body>
                  </Card>
                </Box>
              </Layout.Section>
            </Layout>
            <Box display="flex" justifyContent="center">
              <Button
                disabled={loading}
                appearance="primary"
                onClick={handleSubmit}
              >
                {loading ? <Spinner color="currentColor" size="small" /> : ''}
                Salvar marca
              </Button>
            </Box>
          </Box>
        </Page.Body>
      </Page>

      {/* BUSCA PRODUTOS */}
      <Sidebar
        padding="base"
        open={openSearchProdut}
        onRemove={() => setOpenSearchProdut(false)}
      >
        <Sidebar.Header>
          <Box display="grid" gap="2">
            <Title as="h4">Buscar produtos</Title>
            <Input.Search
              placeholder="Busque por nome, sku ou tags"
              onChange={handleChangeProductQuerySearch}
              value={searchProductQuery}
            />
          </Box>
        </Sidebar.Header>
        <Sidebar.Body>
          {loadingProducts ? (
            <InteractiveList>
              <InteractiveList.CheckboxItemSkeleton title="" />
              <InteractiveList.CheckboxItemSkeleton title="" />
              <InteractiveList.CheckboxItemSkeleton title="" />
              <InteractiveList.CheckboxItemSkeleton title="" />
              <InteractiveList.CheckboxItemSkeleton title="" />
            </InteractiveList>
          ) : products.length ? (
            <>
              <InteractiveList>
                {products.map((product: any, index: number) => (
                  <InteractiveList.CheckboxItem
                    key={index}
                    title={''}
                    showTitle={false}
                    checkbox={{
                      name: 'checkbox-element-' + index,
                      checked:
                        (typeSearchProduct === 'product' &&
                          brand.products.length &&
                          brand.products.find(
                            (item) => item.id === product.id,
                          )) ||
                        (typeSearchProduct === 'rule' &&
                          brand.cart_rules.length &&
                          brand.cart_rules.find(
                            (item) => item.id === product.id,
                          ))
                          ? true
                          : false,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      onChange: (event: ChangeEvent<HTMLInputElement>) =>
                        handleAddProductToBrand(event, product),
                    }}
                  >
                    <Box display="flex" alignItems="center" gap="3">
                      {product.images.length ? (
                        <Thumbnail
                          src={product.images[0].src}
                          alt={product.name[lang]}
                          width="40px"
                        />
                      ) : (
                        <Thumbnail alt={product.name[lang]} width="40px" />
                      )}
                      <Text>{product.name[lang]}</Text>
                    </Box>
                  </InteractiveList.CheckboxItem>
                ))}
              </InteractiveList>
              {productsMeta && productsMeta.pages > 1 ? (
                <Pagination
                  activePage={productsMeta.page}
                  onPageChange={(page) => searchProducts(page)}
                  pageCount={productsMeta.pages}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <EmptyMessage
              title="Produtos"
              text="Nenhum produto encontrado para esta consulta"
              icon={<SearchIcon size={32} />}
              actions={
                <Button
                  appearance="primary"
                  onClick={() => {
                    setSearchProductQuery('');
                    searchProducts(1, true);
                  }}
                >
                  <Icon color="currentColor" source={<UndoIcon />} />
                  Limpar buscar
                </Button>
              }
            />
          )}
        </Sidebar.Body>
      </Sidebar>

      {/* BUSCA CLIENTES */}
      <Sidebar
        padding="base"
        open={openSearchCustomer}
        onRemove={() => setOpenSearchCustomer(false)}
      >
        <Sidebar.Header>
          <Box display="grid" gap="2">
            <Title as="h4">Buscar clientes</Title>
            <Input.Search
              placeholder="Busque por nome, e-mail ou CNPJ"
              onChange={handleChangeCustomerQuerySearch}
              value={searchCustomerQuery}
            />
          </Box>
        </Sidebar.Header>
        <Sidebar.Body>
          {loadingCustomers ? (
            <InteractiveList>
              <InteractiveList.CheckboxItemSkeleton title="" />
              <InteractiveList.CheckboxItemSkeleton title="" />
              <InteractiveList.CheckboxItemSkeleton title="" />
              <InteractiveList.CheckboxItemSkeleton title="" />
              <InteractiveList.CheckboxItemSkeleton title="" />
            </InteractiveList>
          ) : customers.length ? (
            <>
              <InteractiveList>
                {customers.map((customer: any, index: number) => (
                  <InteractiveList.CheckboxItem
                    key={index}
                    title={''}
                    showTitle={false}
                    checkbox={{
                      name: 'checkbox-element-' + index,
                      checked:
                        brand.customers.length &&
                        brand.customers.find((item) => item.id === customer.id)
                          ? true
                          : false,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      onChange: (event: ChangeEvent<HTMLInputElement>) =>
                        handleAddCustomersToBrand(event, customer),
                    }}
                  >
                    <Box display="grid" gap="3">
                      <Text>{customer.name}</Text>
                      <Text>{customer.email}</Text>
                      <Text>{customer.identification}</Text>
                    </Box>
                  </InteractiveList.CheckboxItem>
                ))}
              </InteractiveList>
              {customersMeta && customersMeta.pages > 1 ? (
                <Pagination
                  activePage={customersMeta.page}
                  onPageChange={(page) => searchCustomers(page)}
                  pageCount={customersMeta.pages}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <EmptyMessage
              title="Clientes"
              text="Nenhum cliente encontrado para esta consulta"
              icon={<SearchIcon size={32} />}
              actions={
                <Button
                  appearance="primary"
                  onClick={() => {
                    setSearchCustomerQuery('');
                    searchCustomers(1, true);
                  }}
                >
                  <Icon color="currentColor" source={<UndoIcon />} />
                  Limpar buscar
                </Button>
              }
            />
          )}
        </Sidebar.Body>
      </Sidebar>
    </>
  );
}

export default BrandPage;
