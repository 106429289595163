import React, { ChangeEvent, useEffect, useState } from 'react';

import nexo from '../../nexoClient';

import { Page, Layout } from '@nimbus-ds/patterns';
import { Text, useToast } from '@nimbus-ds/components';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import { useTranslation } from 'react-i18next';
import {
  IStoreConfig,
  defaultStoreConfig,
} from '../../lib/interfaces/config.interfaces';
import apiInstance from '../../utils/apiUtils';

const ConfigsPage: React.FC = () => {
  const { t } = useTranslation();

  const { addToast } = useToast();
  const [loadingGetConfig, setLoadingGetConfig] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [config, setConfig] = useState<IStoreConfig | null>(null);

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: `Voltar` });
    getConfig();
    setLoading(false);
  }, []);

  const handleChangeConfig = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value, type } = event.target;

    const helpConfig: any = { ...config };
    helpConfig[name] =
      type === 'checkbox' ? (event.target as HTMLInputElement).checked : value;
    setConfig({ ...helpConfig });
    return false;
  };

  const getConfig = async () => {
    try {
      const result = await apiInstance.get(`/store/config`);
      const tempConfig = { ...(result.data as IStoreConfig) };

      setConfig({ ...tempConfig });
    } catch (error) {
      setConfig({ ...defaultStoreConfig });
    }
    setLoadingGetConfig(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const data = { ...config };
      delete data._id;
      delete data.createdAt;
      delete data.updatedAt;
      delete data.store_id;
      const result = await apiInstance.post(`/store/config`, data);
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'success',
        text: `${t('ConfigsPage.submit_success')}`,
      });
      setLoading(false);
    } catch (error) {
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'danger',
        text: `${t('ConfigsPage.submit_danger')}`,
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Page>
        <Page.Header title={t('ConfigsPage.title')} />
        <Page.Body>
          <Layout columns="1">
            <Layout.Section>
              <Text>Teste</Text>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </>
  );
};

export default ConfigsPage;
