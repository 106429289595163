import React, { useEffect, useState } from 'react';

import { Alert, Box, Button, Spinner } from '@nimbus-ds/components';
import apiInstance from '../../utils/apiUtils';

const AppStatusBar: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [loadingActive, setLoadingActive] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    getStatus();
  }, []);

  const getStatus = async () => {
    try {
      const result = await apiInstance.get(`/app/status`);
      const data = { ...(result.data as { is_active: boolean }) };

      setActive(data.is_active);
    } catch (error) {
      setActive(false);
    }
    setLoading(false);
  };

  const startStopApp = async () => {
    try {
      setLoadingActive(true);
      const result = await apiInstance.post(
        `/app/${active ? 'stop' : 'start'}`,
      );

      if (result.status === 200) {
        setActive(!active);
      }
    } catch (error) {
      console.log(error);
    }
    setLoadingActive(false);
  };

  if (loading) {
    return <></>;
  }

  return (
    <Box marginBottom="5">
      {active ? (
        <Alert appearance="success" title="Aplicativo Ativo em sua Loja!">
          Ótimo! O aplicativo foi instalado e está ativo em sua loja. Se você
          fez alterações nas configurações e está enfrentando problemas,
          recomendamos desativar e reativar o aplicativo para limpar o cache.
          <Box display="flex" flexWrap="wrap" gap="2">
            <Button
              disabled={loadingActive}
              onClick={startStopApp}
              appearance="neutral"
            >
              {loadingActive ? (
                <>
                  <Spinner color="currentColor" size="small" /> Desativando app
                </>
              ) : (
                `Desativar Aplicativo`
              )}
            </Button>
          </Box>
        </Alert>
      ) : (
        <Alert appearance="danger" title="Aplicativo Desabilitado 😞">
          Atenção! O aplicativo está instalado em sua loja, mas está desativado.
          Isso impede que os recursos essenciais funcionem corretamente.
          <br />
          Para resolver esse problema e aproveitar todos os recursos
          disponíveis, clique no botão abaixo para ativar o aplicativo em sua
          loja.
          <Box display="flex" flexWrap="wrap" gap="2">
            <Button
              disabled={loadingActive}
              onClick={startStopApp}
              appearance="neutral"
            >
              {loadingActive ? (
                <>
                  <Spinner color="currentColor" size="small" /> Ativando app
                </>
              ) : (
                `Ativar Aplicativo`
              )}
            </Button>
          </Box>
        </Alert>
      )}
    </Box>
  );
};

export default AppStatusBar;
