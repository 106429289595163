export interface IBrandCustomer {
  id: number;
  name: string;
  email: string;
  phone: string;
  identification: string;
}

export interface IBrandProduct {
  id: number;
  name: string;
}

export interface IBrandProductCartRule {
  id: number; // id do produto
  name: string; // nome do produto
  min_first_order?: number; // quantidade minima para compra
  min: number; // quantidade minima para compra
}

export interface IBrands {
  _id?: string;
  store_id: number;
  active: boolean;
  name: string;
  customers: IBrandCustomer[];
  products: IBrandProduct[];
  cart_rules: IBrandProductCartRule[];
  colors: {
    main_foreground: string;
    main_background: string;
    accent_color: string;
    secondary_color: string;
    button_background: string;
    button_foreground: string;
    label_background: string;
    label_foreground: string;
    header_background: string;
    header_foreground: string;
    footer_background: string;
    footer_foreground: string;
  };
  createdAt?: string;
  updatedAt?: string;
}

export const EmptyBrand: IBrands = {
  store_id: 0,
  active: true,
  name: '',
  customers: [],
  products: [],
  cart_rules: [],
  colors: {
    main_foreground: '#333333',
    main_background: '#ffffff',
    accent_color: '#FF3333',
    secondary_color: '#333333',
    button_background: '#FFF000',
    button_foreground: '#FFFFFF',
    label_background: '#FFFFFF',
    label_foreground: '#333333',
    header_background: '#F3F3F3',
    header_foreground: '#333333',
    footer_background: '#F3F3F3',
    footer_foreground: '#333333',
  },
};
