import { useEffect } from 'react';

import { Box } from '@nimbus-ds/components';
import { Layout, Page } from '@nimbus-ds/patterns';
import { navigateHeaderRemove } from '@tiendanube/nexo/helpers';

import nexo from '../../nexoClient';
import { AppStatusBar } from '../../components';
import BrandsPage from './BrandsPage';

function PageMain() {
  useEffect(() => {
    navigateHeaderRemove(nexo);
  }, []);

  return (
    <>
      <Page maxWidth="100%">
        <Page.Header title="Papello B2B - Configurações" buttonStack={<></>} />
        <Page.Body>
          <AppStatusBar />
          <Layout columns="1">
            <Layout.Section>
              <Box display="grid" gap="10">
                <BrandsPage />
              </Box>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </>
  );
}

export default PageMain;
