import {
  Box,
  Button,
  Card,
  Icon,
  IconButton,
  Link,
  Pagination,
  Skeleton,
  Table,
  Text,
  Textarea,
  Title,
  useToast,
} from '@nimbus-ds/components';
import { useEffect, useState } from 'react';
import apiInstance from '../../utils/apiUtils';
import { IBrands } from '../../lib/interfaces/brands.interfaces';
import { IPaginateMeta } from '../../lib/interfaces/paginate.interfaces copy';
import { EditIcon, PlusCircleIcon, TrashIcon } from '@nimbus-ds/icons';
import { useHistory } from 'react-router-dom';
import { EmptyMessage } from '@nimbus-ds/patterns';
import nexo from '../../nexoClient';

function BrandsPage() {
  const { push } = useHistory();
  const { addToast } = useToast();

  const [loading, setLoading] = useState<boolean>(true);
  const [brands, setBrands] = useState<IBrands[]>([]);
  const [jsonObject, setJsonObject] = useState<string>('');
  const [brandsMeta, setBrandsMeta] = useState<IPaginateMeta>();

  const getBrands = async (page = 1) => {
    try {
      const result = await apiInstance.get(`/brand?page=${page}`);

      if (result.data !== null) {
        setBrands(result.data.result as IBrands[]);
        setBrandsMeta(result.data.meta as IPaginateMeta);

        getJson();
      }
    } catch (error) {
      setBrands([]);
      addToast({
        id: 'error_get' + new Date().toISOString(),
        type: 'danger',
        text: 'Erro ao consultar marcas',
      });
    }
    setLoading(false);
  };

  const getJson = async () => {
    try {
      const result = await apiInstance.get(`/brand/json/to-json`);

      if (result.data !== null) {
        setJsonObject(JSON.stringify(result.data));
      }
    } catch (error) {
      setJsonObject('');
      addToast({
        id: 'error_get' + new Date().toISOString(),
        type: 'danger',
        text: 'Erro ao consultar objeto JSON',
      });
    }
  };

  const handleDeleteBrand = async (id: string) => {
    try {
      await apiInstance.delete(`/brand/${id}`);
      addToast({
        id: 'error_get' + new Date().toISOString(),
        type: 'success',
        text: 'marca removida',
      });
      getBrands(brandsMeta?.page || 1);
    } catch (error) {
      addToast({
        id: 'error_get' + new Date().toISOString(),
        type: 'danger',
        text: 'Erro ao remover marcas',
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getBrands();
  }, []);

  return (
    <Box display="grid" gap="2">
      <Box
        display="flex"
        gap="2"
        justifyContent="space-between"
        alignItems="center"
      >
        <Title as="h2">Marcas</Title>

        <Button appearance="primary" onClick={() => push('/brands')}>
          <Icon color="currentColor" source={<PlusCircleIcon />} />
          Cadastrar nova marca
        </Button>
      </Box>
      <Box display="grid" gap="10" boxSizing="border-box" marginTop="2">
        {loading || brands.length ? (
          <>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Cell as="th" width="100px">
                    Ativo?
                  </Table.Cell>
                  <Table.Cell as="th">Marca</Table.Cell>
                  <Table.Cell as="th">Produtos</Table.Cell>
                  <Table.Cell as="th">Usúarios</Table.Cell>
                  <Table.Cell as="th">Regras de Carrinho</Table.Cell>
                  <Table.Cell as="th" width="100px">
                    {''}
                  </Table.Cell>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {loading
                  ? Array.from({ length: 3 }, (_, i) => (
                      <Table.Row key={i}>
                        <Table.Cell>
                          <Skeleton
                            height="1rem"
                            width="100%"
                            borderRadius="3"
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Skeleton
                            height="1rem"
                            width="100%"
                            borderRadius="3"
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Skeleton
                            height="1rem"
                            width="100%"
                            borderRadius="3"
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Skeleton
                            height="1rem"
                            width="100%"
                            borderRadius="3"
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Skeleton
                            height="1rem"
                            width="100%"
                            borderRadius="3"
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))
                  : brands.map((brand) => (
                      <Table.Row key={brand._id!}>
                        <Table.Cell as="td">
                          {brand.active ? (
                            <Text color="success-textLow" fontWeight="bold">
                              SIM
                            </Text>
                          ) : (
                            <Text color="danger-textLow" fontWeight="bold">
                              NÃO
                            </Text>
                          )}
                        </Table.Cell>
                        <Table.Cell as="td">{brand.name}</Table.Cell>
                        <Table.Cell as="td">
                          {brand.products.length} produto(s)
                        </Table.Cell>
                        <Table.Cell as="td">
                          {brand.customers.length} usuário(s)
                        </Table.Cell>
                        <Table.Cell as="td">
                          {brand.cart_rules.length} regra(s)
                        </Table.Cell>
                        <Table.Cell as="td">
                          <Box display="flex" justifyContent="flex-end" gap="3">
                            <IconButton
                              source={<EditIcon size="small" />}
                              size="30px"
                              onClick={() => push(`/brands/${brand._id!}`)}
                            />
                            <IconButton
                              source={<TrashIcon size="small" />}
                              size="30px"
                              onClick={() => handleDeleteBrand(brand._id!)}
                            />
                          </Box>
                        </Table.Cell>
                      </Table.Row>
                    ))}
              </Table.Body>
            </Table>
            {brandsMeta && brandsMeta.pages > 1 ? (
              <Pagination
                activePage={brandsMeta?.page || 1}
                onPageChange={getBrands}
                pageCount={brandsMeta?.pages || 0}
              />
            ) : (
              <></>
            )}

            {jsonObject ? (
              <>
                <Card>
                  <Card.Header>
                    <Box display="flex" justifyContent="space-between">
                      <Title as="h3">Objeto de Configuração</Title>
                      <Button
                        onClick={() => {
                          nexo.dispatch({
                            type: 'app/navigate/goToOldAdmin',
                            payload: {
                              pathToOldAdmin:
                                '/themes/settings/active/#instatheme=configuracoes-restrincoes',
                            },
                          });
                        }}
                        appearance="neutral"
                      >
                        Abrir campo personalizado
                      </Button>
                    </Box>
                  </Card.Header>
                  <Card.Body>
                    <Box display="grid" gap="6">
                      <Text>
                        Quando efetuar a inserção, modificação ou remoção de
                        marcas, por favor, assegure-se de copiar e colar o
                        objeto JSON das configurações fornecido abaixo no campo
                        personalizado da loja.
                      </Text>

                      <Textarea
                        lines={10}
                        id="json"
                        name="json"
                        value={jsonObject}
                      />
                    </Box>
                  </Card.Body>
                </Card>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <Card>
            <Card.Body>
              <Box padding="10">
                <EmptyMessage
                  title="Nenhuma marca encontrada"
                  text="Vamos começar a cadastrar a primeira marca"
                  icon={<PlusCircleIcon size={32} />}
                  actions={
                    <Link appearance="primary" onClick={() => push('/brands')}>
                      <Icon color="currentColor" source={<PlusCircleIcon />} />
                      Cadastrar primeira marca
                    </Link>
                  }
                />
              </Box>
            </Card.Body>
          </Card>
        )}
      </Box>
    </Box>
  );
}

export default BrandsPage;
